import { createSlice } from "@reduxjs/toolkit";
import { langApi } from "../apis/langApi";
import { modulesApi } from "../apis/modulesApi";
import { blocksApi } from "../apis/blocksApi";
import { termsApi } from "../apis/termsApi";

 const defaultState = {
    isLogged: false,
    isLoading: true,
    confirm: false,
    langs: false
 };

  const mainSlice = createSlice({
    name: "main",
    initialState: defaultState,
    reducers: {
       setIslogged(store, action){
          store.isLogged = action.payload;
       },
       setIsLoading(store, action){
         store.isLoading = action.payload;
       },
       setConfirm(store, action){
         store.confirm = action.payload;
       }
    },

    extraReducers: (builder) => {
      builder
      .addMatcher(
         langApi.endpoints.getLangs.matchFulfilled,
         (state, action) => {
            state.langs = action.payload.data;
         }
      )
      .addMatcher(
         modulesApi.endpoints.updatePost.matchFulfilled,
         (state, action) => {
            const anchor = document.getElementsByClassName(`anchor-${action.payload.id}`)
            let i = 0
            const timeout = setInterval(()=>{
               if(anchor.length){
                   const targetElement = anchor[0] as HTMLElement;

                   window.scrollTo({
                       top: targetElement?.offsetTop - (window.innerHeight / 2),
                       left: 100,
                       behavior: "smooth",
                   })
                  clearInterval(timeout)
               }
               i++
               if(i > 50) clearInterval(timeout)
            }, 500)
         }
     )
     .addMatcher(
      blocksApi.endpoints.updateBlockNew.matchFulfilled,
      (state, action) => {
         const anchor = document.getElementsByClassName(`anchor-${action.payload.data.id}`)
         let i = 0
         const timeout = setInterval(()=>{
            if(anchor.length){
                const targetElement = anchor[0] as HTMLElement;

                window.scrollTo({
                  top: targetElement?.offsetTop - (window.innerHeight / 2),
                  left: 100,
                  behavior: "smooth",
               })
               clearInterval(timeout)
            }
            i++
            if(i > 50) clearInterval(timeout)
         }, 500)
      }  
      )
      .addMatcher(
         blocksApi.endpoints.addBlockNew.matchFulfilled,
         (state, action) => {
            const anchor = document.getElementsByClassName(`anchor-${action.payload.block_id}`)
            console.log(anchor);
            let i = 0
            const timeout = setInterval(()=>{
               if(anchor.length){
                   const targetElement = anchor[0] as HTMLElement;

                   window.scrollTo({
                       top: targetElement?.offsetTop - (window.innerHeight / 2),
                       left: 100,
                       behavior: "smooth",
                   })
                  clearInterval(timeout)
               }
               i++
               if(i > 50) clearInterval(timeout)
            }, 500)
         }  
      )
      .addMatcher(
         termsApi.endpoints.updateTerms.matchFulfilled,
         (state, action) => {
            const anchor = document.getElementsByClassName(`anchor-${action.payload.data.id}`)
            let i = 0
            const timeout = setInterval(()=>{
               if(anchor.length){
                   const targetElement = anchor[0] as HTMLElement;

                   window.scrollTo({
                       top: targetElement?.offsetTop - (window.innerHeight / 2),
                       left: 100,
                       behavior: "smooth",
                   })
                  clearInterval(timeout)
               }
               i++
               if(i > 50) clearInterval(timeout)
            }, 500)
         }  
      )
  }

  });

export const { 
   setIslogged,
   setIsLoading,
   setConfirm
} = mainSlice.actions;
export default mainSlice.reducer;