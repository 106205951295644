import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {store} from "./redux/store";
import { CheckToken } from './utils/checkToken';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
      <BrowserRouter>
          <Provider store={store}>
              <CheckToken>
                  <App/>
              </CheckToken>
          </Provider>
      </BrowserRouter>
);
