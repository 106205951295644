import React, {useState} from 'react';

// @ts-ignore
import s from './Auth.module.scss';

// @ts-ignore
import background from '../../assets/images/background.webp';
// @ts-ignore
import logo from '../../assets/icons/logo.svg';
// @ts-ignore
import close from '../../assets/icons/close.svg';
// @ts-ignore
import eyeClosed from '../../assets/icons/eyeClosed.svg';
// @ts-ignore
import show from '../../assets/icons/show.svg';

import {Formik, Form, Field, useField} from 'formik';
import * as yup from 'yup';
import {useAuthMutation} from "../../redux/apis/userApi";
import {useCrypto} from "../../utils/crypto";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setIsLoading} from "../../redux/slices/mainSlice";
import {PrimaryButton} from "../../components/PrimaryButton/PrimaryButton";

export const Auth = () => {
    const [auth, {data, isLoading, isError}] = useAuthMutation();
    let response = "";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [passwordLength, setPasswordLength] = useState(0);

    const initialValues = { email: '', password: ''}
    const validationSchema = yup.object().shape({
        password: yup.string()
            .required('Обов\'язкове поле')
            .min(14, 'Пароль має містити не менше 14 символів'),
        email: yup.string().required('Обязательное поле').email('Не корректный email')
    });

    function LogIn(values: any){
        const email = values.email;
        const password = useCrypto(values.password);
        auth({email: email, password: password});
    }

    if (isError) console.log(isError);
    if (isLoading) dispatch(setIsLoading(true));
    else dispatch(setIsLoading(false));
    if (data) {
        if(data.message === 8) response = "Не правильный email или пароль";
        if(data.message === 10){
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("userData", JSON.stringify(data.data.userData));
            navigate("/");
        }
    }

    return (
        <div className={s.container}>
            <div className={s.login}>
                <img src={logo} alt={s.logo}/>

                <div className={s.wrap_form}>
                    <h1>Log In</h1>
                    <Formik
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={(values)=> LogIn(values)}
                    >
                        {({
                              errors,
                              touched,
                              isValid,
                              dirty,
                              setFieldValue
                        }) => (
                            <Form className={s.auth_form}>
                                <div className={s.form}>
                                    <label>Email</label>
                                    <div className={s.inputContainer}>
                                        <img
                                            src={close}
                                            alt={s.deleteIcon}
                                            className={s.iconInput}
                                            onClick={() => setFieldValue('email', '')}
                                        />
                                        <Field
                                            name="email"
                                            type="text"
                                            placeholder={"Enter your email"}
                                            className={errors.email && s.error}>
                                        </Field>
                                    </div>
                                    <span className={s.error_text}>{touched.email ? errors.email : null}</span>
                                </div>

                                <div className={s.form}>
                                    <label>Password</label>
                                    <div className={s.inputContainer}>
                                        <img
                                            src={showPassword ? show : eyeClosed}
                                            alt="Toggle password visibility"
                                            className={s.iconInputEye}
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                        <Field
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder={"Enter your password"}
                                            className={`${errors.password ? s.error : ''} ${s.passwordField}`}
                                            /*onChange={(e: any) => {
                                                const value = e.target.value;
                                                setFieldValue('password', value);
                                                setPasswordLength(value.length);
                                            }}*/
                                        />
                                    </div>
                                    {/*<div className={s.progress}>
                                        <span className={passwordLength >= 4 && s.progress_4} />
                                        <span className={passwordLength >= 8 && s.progress_8} />
                                        <span className={passwordLength >= 12 && s.progress_12} />
                                        <span className={passwordLength >= 16 && s.progress_16} />
                                    </div>*/}
                                    <span className={s.error_text}>{touched.password ? errors.password : null}</span>
                                </div>

                                <PrimaryButton
                                    type="submit"
                                    disabled={!isValid || !dirty}
                                    text={"Увійти"}
                                />
                                <span className={s.error_text}>{response}</span>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <div className={s.image}>
                <img src={background} alt={s.loginImage} />
            </div>
        </div>
    )
}