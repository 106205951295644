import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const api = process.env.REACT_APP_API_ADDRESS;

export const pagesApi = createApi({
    reducerPath: 'pagesApi',
    tagTypes: ['Pages'],
    baseQuery: fetchBaseQuery({
      baseUrl: api,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if(token) headers.set("token", token);
        return headers;
      }
    }),
    endpoints: (builder) => ({

      getPages: builder.query({
        query: () =>  `/api/pages/get`,
        providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }: any) => ({ type: 'Pages', id })),
              { type: 'Pages', id: 'LIST' },
            ]
          : [{ type: 'Pages', id: 'LIST' }],
      }),

      addPage: builder.mutation({
        query: (data) => ({
          url: '/api/pages/set',
          method: 'POST',
          body: data,
        }),
      }),

      updatePage: builder.mutation({
        query: (data) => ({
          url: '/api/pages/update',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [{ type: 'Pages', id: 'LIST' }],
      }),

      addSectionToPage: builder.mutation({
        query: (data) => ({
          url: '/api/pages/addSection',
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: [{ type: 'Pages', id: 'LIST' }],
      }),

      deletePage: builder.mutation({
        query: (id) => ({
          url: '/api/pages/delete',
          method: 'DELETE',
          body: {page_id: id},
        }),
        invalidatesTags: [{ type: 'Pages', id: 'LIST' }],
      }),
      

      }),
})

export const {
    useGetPagesQuery,
    useAddPageMutation,
    useUpdatePageMutation,
    useAddSectionToPageMutation,
    useDeletePageMutation
} = pagesApi;