import React from "react";
import "./PrimaryButton.module.scss";

type PropsButton = {
    disabled: boolean;
    type: "submit" | "reset" | "button" | undefined;
    text: string;
}

export const PrimaryButton = ({ disabled, type, text }: PropsButton) => {
    return (
        <button type={type} disabled={disabled}>{text}</button>
    )
}