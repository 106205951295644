import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Auth} from "./pages/Auth/Auth";
import {Main} from "./pages/Main/Main";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<Main />}/>
        <Route path="/auth" element={<Auth />}/>
      </Routes>
    </div>
  );
}

export default App;
